import jQuery from 'jquery';
import 'bootstrap/js/dist/modal';

window.$ = window.jQuery = jQuery;

$(document).ready(() => {
    console.log('    _    _\n' +
        '   (o)--(o)\n' +
        '  /.______.\\\n' +
        '  \\________/\n' +
        ' ./        \\.\n' +
        '( .        , )\n' +
        ' \\ \\_\\\\//_/ /\n' +
        '  ~~  ~~  ~~');
});
